<!--
 * @Author: lbw
 * @Date: 2022-08-13 12:51:51
 * @LastEditors: Do not edit
 * @LastEditTime: 2025-03-13 12:07:56
 * @Description:
-->

<template>
  <div
    class="warp"
    v-loading="loading"
    :element-loading-text="$t('message.Loadingdesperately')"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="cententbox" ref="pageh">
      <!--搜索栏-->
      <div class="heaedercl disflex flexal">
        <div>
          <el-button v-show="showbtn" type="primary" @click="backbtn">{{
            $t("message.refund")
          }}</el-button>
        </div>
        <div class="disflex flexal">
          <span class="marright marginleftcl">{{ $t("message.users") }}：</span>
          <el-select
            v-model="Uid"
            :placeholder="$t('message.Pleaseselectauser')"
            class="inputwid160"
            filterable
          >
            <el-option
              v-for="item in userlists"
              :key="item.User_ID"
              :label="item.User_Name"
              :value="item.User_ID"
            >
            </el-option>
          </el-select>

          <div>
            <span class="marright marginleftcl">{{ $t("message.startingtime1") }}：</span>
            <el-date-picker
              v-model="vv1"
              type="datetime"
              align="right"
              :picker-options="pickerOptions"
              :placeholder="$t('message.Pleaseselectadateandtime')"
            >
            </el-date-picker>
          </div>

          <div>
            <span class="marright marginleftcl">{{ $t("message.EndTime") }}：</span>
            <el-date-picker
              v-model="vv2"
              type="datetime"
              align="right"
              :picker-options="pickerOptions"
              :placeholder="$t('message.Pleaseselectanendtime')"
            >
            </el-date-picker>
          </div>
          <div style="margin: 0px 10px">
            <el-button type="primary" @click="search">{{
              $t("message.search")
            }}</el-button>
          </div>
        </div>
      </div>
      <!--内容-->
      <!--谷歌地图-->
      <div
        class="baidu-api"
        v-show="mapType == 'Wgs84'"
        v-loading="!gooleWc"
        :element-loading-text="$t('message.Loadingdesperately')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        id="googlemap"
      ></div>
    </div>
  </div>
</template>

<script></script>
<script>
import global from "@/method/globalMethod.js";
import beasconfig from "@/api/beasconfig.js";
import { queryUserName } from "@/administration/history.js";
import axios from "axios";
import qs from "qs";
import moment from "moment";
const gpsUrlapi = beasconfig.gpsUrlapi;
// var apiUrl = JSON.parse(sessionStorage.getItem("http"));
// let item = apiUrl.find(item => item.name == 'nginx');
// let api = item.http;
// let port = item.port;
export default {
  name: "groupGps",
  data() {
    return {
      // status: '',
      // tiemvalue1: '',
      // tiemvalue2: '',
      // uId: '',

      // btime1:'',
      // btime2:'',
      // bvv1:'',
      // bvv2:'',
      // bid:'',
      // statusSearch:false,
      /*三个月以前*/
      pickerOptions: {
        disabledDate(time) {
          let curDate = new Date().getTime();
          let three = 90 * 24 * 3600 * 1000;
          let threeMonths = curDate - three;
          return time.getTime() > Date.now() || time.getTime() < threeMonths;
        },
      },
      showbtn: true,
      time1: "",
      time2: "",
      vv1: "",
      vv2: "",
      Uid: "",
      isOrientation: "",
      userlists: [],
      loading: false,
      pslist: [],
      timeNum: 0,
      mapType: "Wgs84",
      gooleWc: false,
      gooleCover: [], //覆盖物数组
      language: localStorage.getItem("languageStorage") || "en",
    };
  },
  mounted() {
    this.initMap();
  },
  watch: {
    uId(val) {
      if (val == -1) {
        this.uId = "";
      }
    },
  },
  methods: {
    cutMap(type) {
      //切换地图
      if (this.mapType != type) {
        this.mapType = type;
        if (this.gooleWc == false) {
          //判断地图是否有加载出来
          this.initMap();
        }
        this.getTrajectoryMethod();
      }
    },
    /*历史轨迹*/
    getTrajectoryMethod() {
      // console.log('this.statusSearch', this.statusSearch)
      var tiem1 = moment(this.vv1).format("YYYY-MM-DD");
      var tiem2 = moment(this.vv2).format("YYYY-MM-DD");
      var date1 = moment(this.vv1).valueOf();
      var data2 = moment(this.vv2).valueOf();
      this.bid = this.uId;
      // let daTime = (3600 * 1000 * 8);
      let startDateTamp = moment(this.vv1).valueOf();
      let endDateTamp = moment(this.vv2).valueOf();
      let vv1 = moment(startDateTamp).format("YYYY-MM-DD HH:mm:ss"); //海外需要加8小时
      let vv2 = moment(endDateTamp).format("YYYY-MM-DD HH:mm:ss"); //海外需要加8小时
      /!*判断是否为同一天*!/;
      if (this.vv1 == "" || this.vv1 == null || this.vv2 == "" || this.vv2 == null) {
        this.$MessageWarning(this.$t("message.message.Pleaseselectastartandendtime"));
        return;
      } else if (date1 > data2) {
        this.$MessageWarning(this.$t("message.message.endTimeNoStarttime"));
        return;
      } else if (tiem1 == tiem2) {
        this.time1 = moment(this.vv1).valueOf();
        this.time2 = moment(this.vv2).valueOf();
        this.vv1 = moment(this.vv1).format("YYYY-MM-DD HH:mm:ss");
        this.vv2 = moment(this.vv2).format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.$MessageWarning(
          this.$t("message.message.Thestartandendtimesmustbeonthesameday")
        );
        return;
      }
      // if(this.statusSearch){
      //   var params = {
      //     Uid: this.bid,
      //     CorrdinateType: "baidu",
      //     startDateTime: this.bvv1,
      //     endDateTime: this.bvv2,
      //     startDateTamp: this.btime1, //时间戳13位
      //     endDateTamp: this.btime2
      //   };
      // }else {
      this.loading = true;

      var params = {
        Uid: this.Uid,
        CorrdinateType: this.mapType,
        startDateTime: vv1,
        endDateTime: vv2,
        startDateTamp: startDateTamp, //时间戳13位
        endDateTamp: endDateTamp,
        // positionType:2
      };
      // }

      if (!this.isOrientation) {
        params.positionType = 2;
      }
      axios
        .post(gpsUrlapi + "/shanli/gps/api/trace/gethistory?", qs.stringify(params), {
          headers: beasconfig.tokenSl,
        })
        .then((res) => {
          // console.log('轨迹数据', res.data.Data)
          if (res.data.Status == 1 && res.data.Data.length > 0) {
            var pots = [];
            var rectifyPoints = [];
            res.data.Data.forEach((el) => {
              if (el.Lng && el.Lat) {
                rectifyPoints.push({
                  coord_type_input: "bd09ll",
                  latitude: el.Lat,
                  longitude: el.Lng,
                  loc_time: Number(el.Rtimestamp) / 1000,
                  speed: el.Speed,
                });
                pots.push({ lat: el.Lat, lng: el.Lng });
              }
            });
            this.pslist = pots;
            this.drawHistroyTrace(pots, rectifyPoints);
            this.loading = false;
          } else {
            this.loading = false;
            this.$MessageWarning(this.$t("message.noData"));
          }
        });
    },
    // 轨迹纠偏
    rectifyTrace(points, cb) {
      // isBindRoad  是否绑路 0是不绑路 1是绑路
      // var _param = 'ak=UZfTF1PQc3R9GaMg7GEaADbWw1h9udhb&point_list=' + JSON.stringify(points) + '&rectify_option=need_mapmatch:1|denoise_grade:5|transport_mode:auto|vacuate_grade:0';
      var _param = `ak=UZfTF1PQc3R9GaMg7GEaADbWw1h9udhb&point_list=${JSON.stringify(
        points
      )}&rectify_option=need_mapmatch:${
        this.$store.state.userState.isBindRoad
      }|transport_mode:auto|denoise_grade:5|vacuate_grade:0`;
      axios
        .post(
          `${beasconfig.xinextendUrlapi}/gps/getBaiDu`,
          qs.stringify({
            url: "http://api.map.baidu.com/rectify/v1/track",
            reqType: 1,
            param: _param,
          }),
          {
            headers: beasconfig.tokenSl,
          }
        )
        .then((res) => {
          // console.warn(res)
          const data = JSON.parse(res.data.data);
          cb && cb(data.points);
          // console.log('轨迹点', res)
          this.loading = false;
        });
    },
    clearOverlays() {
      //清除所添加覆盖物
      if (this.gooleCover.length > 0) {
        this.gooleCover.forEach((item) => {
          item.setMap(null);
        });
        this.gooleCover = [];
      }
    },
    // 绘制两点之间的路径
    drawRoute(pois) {
      const flightPath = new google.maps.Polyline({
        path: pois,
        geodesic: true,
        strokeColor: "#999",
        strokeOpacity: 1.0,
        strokeWeight: 6,
      });

      const flightPath2 = new google.maps.Polyline({
        path: pois,
        geodesic: true,
        strokeColor: "#4cdd25",
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });
      flightPath.setMap(this.maps);
      flightPath2.setMap(this.maps);
      this.gooleCover.push(flightPath, flightPath2);
      this.setZoom(pois);
    },
    // 根据点的数组自动调整缩放级别
    setZoom(pois) {
      if (this.mapType == "Wgs84") {
        var latlngbounds = new google.maps.LatLngBounds(); //根据点的数组自动调整缩放级别
        pois.forEach((item) => {
          latlngbounds.extend(item);
        });
        this.maps.fitBounds(latlngbounds);
        this.maps.panToBounds(latlngbounds);
      }
    },
    showMarkerLabel(id, type) {
      //显示盒子
      var t = document.getElementById(`dw${id}`); //选取label为百度baidu的id进行隐藏
      t.style.display = type == 1 ? "block" : "none"; // 以块级样式隐藏
    },
    // 绘制轨迹
    drawHistroyTrace(pois, dwArr) {
      // 先清除上次画的轨迹
      // 先清除上次画的轨迹
      this.clearOverlays(); //先清除上次画的轨迹
      var gps = {}; //找出相同的定位点
      dwArr.forEach((item, index) => {
        var pint = item.loc_time;
        if (!gps[pint]) {
          //判断是否有这个数组
          var html = `<div class='bd_circular mouse_dw'><div id="dw${index}" class="ds_box ds_none">${this.$moment(
            item.loc_time * 1000
          ).format(
            "YYYY-MM-DD HH:mm:ss"
          )}<div class="user_big" ><div class='small'></div></div></div></div>`;
          gps[pint] = [item];
          if (this.mapType == "Wgs84") {
            var position = new google.maps.LatLng(item.latitude, item.longitude);
            const markerLabel = global.addHtml(1, {
              map: this.maps,
              content: html,
              position: position,
            });
            let i = 100000 + index;
            markerLabel.zIndex = i;
            markerLabel.czindex = i;
            markerLabel.id = index;
            markerLabel.addEventListener("mouseover", () => {
              this.showMarkerLabel(markerLabel.id, 1);
            });
            markerLabel.addEventListener("mouseout", () => {
              this.showMarkerLabel(markerLabel.id, 0);
            });
            this.gooleCover.push(markerLabel);
          }
        }
      });
      this.drawRoute(pois);
      this.addMarker(
        {
          lng: pois[0].lng,
          lat: pois[0].lat,
        },
        "start"
      );
      this.addMarker(
        {
          lng: pois[pois.length - 1].lng,
          lat: pois[pois.length - 1].lat,
        },
        "end"
      );
    },
    // 创建覆盖物
    addMarker(point, type) {
      var pos = point;
      if (pos) {
        var iconSrc = "";
        if (type == "start") {
          iconSrc = require(this.language == "zh"
            ? "../../assets/map/start.png"
            : "../../assets/map/start_hw.png");
        }
        if (type == "end") {
          iconSrc = require(this.language == "zh"
            ? "../../assets/map/end.png"
            : "../../assets/map/end_hw.png");
        }
        var position = new google.maps.LatLng(pos.lat, pos.lng);
        const marker = global.addHtml(2, {
          map: this.maps,
          content: iconSrc,
          position: position,
          class: "marker_img",
        });
        if (marker) {
          this.gooleCover.push(marker);
        }
      }
    },
    //  谷歌地图
    initMap() {
      if (window.googleMap) {
        //window.googleMap有值就代表地图API已经加载完成
        this.timeNum = 0;
        try {
          this.maps = new google.maps.Map(document.getElementById("googlemap"), {
            zoom: 10,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            //地图中心点，这里我以第一个数据的经纬度来设置中心点
            center: { lat: 38.903804230612835, lng: -77.0365677652717 },
            disableDefaultUI: false,
            zoomControl: false,
            mapId: "4504f8b37365c3d0",
            maxZoom: 17, //18  //最大缩放点
          });
          this.gooleWc = true;
          this.getTrajectoryMethod();
        } catch (error) {
          this.gooleWc = false;
        }
      } else {
        setTimeout(() => {
          this.timeNum += 1;
          if (this.timeNum <= 10) {
            this.initMap();
          }
        }, 1000);
      }
    },
    /*调度对讲*/
    backbtn() {
      // this.$router.go(-1);
      if (this.$route.name == "trajectory") {
        this.$router.push(`/intercom/googleMap`);
      } else {
        this.$router.go(-1);
      }
    },
    /*搜索*/
    search() {
      this.clearOverlays(); //先清除上次画的轨迹
      this.getTrajectoryMethod();
    },
    userlist() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let parmas = {
        uId: userInfo.id,
      };
      queryUserName(parmas).then((res) => {
        if (res.data.code == 200) {
          let users = res.data.data;
          if (users && users.length > 0) {
            this.userlists = users.filter((item) => item.user_isactive == 1); //过滤出为删除的用户
          }
          // this.userlists.unshift({
          //   'User_ID': -1,
          //   'User_Name': '请选择用户'
          // })
        } else if (res.code == 40102) {
        } else {
          this.$MessageWarning(res.data.msg);
        }
      });
    },
  },
  created() {
    /* 是否开启定位功能 */
    this.isOrientation = JSON.parse(this.$route.query.isOrientation);
    /*用户id*/
    this.Uid = parseInt(this.$route.query.uid);
    this.time1 = this.$route.query.tiem1;
    this.time2 = this.$route.query.tiem2;
    this.vv1 = this.$route.query.vv1;
    this.vv2 = this.$route.query.vv2;
    /*查用户*/
    this.userlist();
  },
  watch: {
    $route() {
      this.isOrientation = JSON.parse(this.$route.query.isOrientation);
      /*用户id*/
      this.Uid = parseInt(this.$route.query.uid);
      this.time1 = this.$route.query.tiem1;
      this.time2 = this.$route.query.tiem2;
      this.vv1 = this.$route.query.vv1;
      this.vv2 = this.$route.query.vv2;
      /*查用户*/
      this.userlist();
      this.clearOverlays(); //先清除上次画的轨迹
      /*获取轨迹*/
      this.getTrajectoryMethod();
    },
  },
};
</script>

<style lang="less" scoped>
.warp {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .baidu-api {
    width: 100%;
    height: calc(100vh - 160px);
    margin-top: 10px;
  }

  .cententbox {
    width: 100%;
    padding: 10px 10px 6px 10px;

    // height: calc(100% - 20px);
    .persontextbox {
      height: 42px;
      border-bottom: 1px solid #ddd;

      .persontext {
        display: inline-block;
        height: 41px;
        line-height: 41px;
        padding: 0 20px;
        font-size: 15px;
        color: #888;
        border: 1px solid #ddd;
        border-bottom-color: #e2e2e2;
        border-top-right-radius: #f0f0f0;
        border-top-left-radius: 5px;
        background-color: #e2e2e2;
      }
    }

    .disflex {
      display: flex;
      flex-direction: row;
    }

    .flexal {
      align-items: center;
    }

    .heaedercl {
      justify-content: space-between;
      background: #f0f0f0;
      // margin: 10px 0;
      padding: 10px;
    }

    .inputwid280 {
      width: 280px;
    }

    .marright {
      margin-right: 5px;
    }

    .marginleftcl {
      margin-left: 20px;
    }

    .inputwid160 {
      width: 160px;
    }

    .listbox {
      // max-height: 487px;
      overflow: auto;
    }

    /deep/.el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 240px;
    }

    /deep/ .el-input__inner:focus {
      border-color: var(--main-color);
    }
  }

  /deep/.el-button--primary {
    color: #fff;
    background-color: #5a5557;
    border-color: #5a5557;
  }
}
</style>
